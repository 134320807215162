<template>
  <div>
    <v-autocomplete
      v-model.trim="batchName"
      :items="Batch"
      item-text="batchName"
      item-value="id"
      persistent-hint
      label="Batch Name"
      class="my-0 pt-0"
      @change="getDATA"
    ></v-autocomplete>

    <div class="float-right mt-10">
      <span>
        <a @click="RESET" class="btn btn-danger btn-sm">Cancel</a>
      </span>
      <span class="m-4">
        <span v-if="loader">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </span>
        <a v-else @click="Apply" class="btn btn-success btn-sm">Submit </a>
      </span>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
export default {
  name: "payrollbatchscreen1",
  components: { DateRangePicker },
  mixins: [validationMixin],
  validations: {
    form: {
      batchName: { required },
    },
  },
  computed: {
    ...mapGetters(["Batch"]),
  },
  data() {
    return {
      form: {
        batchName: null,
      },
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      dates: {
        startDate: null,
        endDate: null,
      },
      loader: false,
    };
  },

  mounted() {
    this.dates = {
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date().setDate(new Date().getDate() + 1),
    };
  },
  methods: {
    async getDATA(obj) { 
      this.loader = true;
      await this.$store.dispatch("getBatchData", { id: obj });
      this.loader = false;
    },
    clearRange() {
      this.dates = {
        startDate: null,
        endDate: null,
      };
    },
    dobformat2(obj) {
      if (obj) {
        var date = new Date(obj);
        return (
          (date.getMonth() > 8
            ? date.getMonth() + 1
            : "0" + (date.getMonth() + 1)) +
          "/" +
          (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
          "/" +
          date.getFullYear()
        );
      }
    },
    async Apply() {
      this.$emit("screenOneData", this.form);
      this.$bvModal.hide("filter");
    },
    RESET() {
      (this.form.batchName = []),
        (this.date = []),
        (this.dates = {}),
        this.$bvModal.hide("filter");
    },
  },
};
</script>

<style scoped>
.v-date-picker-table .v-btn.v-btn--active {
  color: #2d79bd !important;
}
.v-btn--outlined {
  border: thin solid #2d79bd !important;
}
.text-color {
  color: #2d79bd;
}
.reportrange-text[data-v-1ebd09d2] {
  background: #fff;
  cursor: pointer;
  padding: 10px 10px;
  border: 1px solid #ccc;
  width: 100%;
  overflow: hidden;
  font-size: 15px;
}
.vue-daterange-picker[data-v-1ebd09d2] {
  display: block;
}
.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}
</style>

<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="menu-icon flaticon-clock-2"></i
        ><span class="ml-3 text-color">Saved Payroll Batches</span>
      </h3>
      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="payroll">
          <i class="material-icons">add</i> <span>View Batch</span></a
        >
      </span>
    </div>
    <b-modal
      size="md"
      left
      text
      centered
      id="filter"
      hide-footer
      no-close-on-backdrop
    >
      <template slot="modal-title"
        ><span class="text-color">View Saved Payroll Batch</span> <br />
        <span style="font-size: 12px; font-weight: 400"
          >Search by Saved Names or Scheduled Pay Date</span
        ></template
      >
      <Payrollbatchscreen-1 @screenOneData="openNext"></Payrollbatchscreen-1>
    </b-modal>
    <b-modal
      size="xl"
      centered
      text
      id="Action"
      hide-footer
      no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5>Viewing Saved Batch</h5>
        <!-- <a
          @click="differPayroll"
          class="btn btn-success btn-sm"
          style="margin-left: 40%"
          >Select Different Employee</a
        > -->
        <v-icon class="btn btn-sm close-btn" @click="close()">mdi-close</v-icon>
      </template>

      <Payrollbatchscreen-2 :form="form"></Payrollbatchscreen-2>
    </b-modal>
  </div>
</template>
<script>
import Payrollbatchscreen1 from "../../components/payroll/payrollbatch/payrollbatchscreen1.vue";
import Payrollbatchscreen2 from "../../components/payroll/payrollbatch/payrollbatchscreen2.vue";
export default {
  components: { Payrollbatchscreen1, Payrollbatchscreen2 },

  data() {
    return {
      form: {},
    };
  },
  watch: {},
  created() {},
  computed: {},
  mounted() {
    this.$store.dispatch("GetBatchName");

    this.$emit("screenOne", this.screenOne);
  },
  methods: {
    openNext(data) {
      this.isFetching = true;
      if ((this.form = true)) {
        this.isFetching = false;
        this.form = data;
        this.$bvModal.show("Action");
      }
    },

    payroll() {
      this.$bvModal.show("filter");
      this.$bvModal.hide("Action");
    },
    // differPayroll() {
    //   ;
    //   this.$bvModal.hide("Action");
    //   this.$bvModal.show("filter");
    // },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.text-color {
  color: #2d79bd;
}
</style>
